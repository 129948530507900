var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { class: _vm.cls },
    [
      _c(
        "div",
        {
          staticClass: "imelink-groupmember__button",
          on: { click: _vm.changeShrink }
        },
        [_c("i", { class: `el-icon-arrow-${_vm.shrink ? "left" : "right"}` })]
      ),
      _c(
        "el-header",
        {
          staticClass: "imelink-header",
          style: {
            opacity: _vm.shrink ? "0" : "1",
            marginLeft: _vm.shrink ? "-100px" : "0"
          },
          attrs: { height: "auto" }
        },
        [_vm._v(" \n  ")]
      ),
      _c(
        "div",
        {
          staticClass: "imelink-groupmember__view",
          style: {
            opacity: _vm.shrink ? "0" : "1"
          }
        },
        [
          _vm.managerBlock
            ? _c(
                "div",
                { staticClass: "operationPopover" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom-end",
                        "visible-arrow": false,
                        trigger: "hover",
                        "popper-class": "imOperationPopover",
                        "popper-options": {
                          boundariesElement: "imelink-groupmember__view",
                          gpuAcceleration: true
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "moreOperation",
                          attrs: { slot: "reference" },
                          slot: "reference"
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: require("../../../assets/images/im/moreOperation.png"),
                              fit: "cover"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "operationPopoverBox" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("block"),
                                placement: "top-start"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "blacklistIcon" },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%"
                                    },
                                    attrs: {
                                      src: require("../../../assets/images/im/blackListIcon.png"),
                                      fit: "cover"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c("el-switch", {
                            on: { change: _vm.changeIsInBlackList },
                            model: {
                              value: _vm.isInBlackList,
                              callback: function($$v) {
                                _vm.isInBlackList = $$v
                              },
                              expression: "isInBlackList"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "voiceToText" },
            [
              _c("div", {
                staticClass: "voiceIcon",
                attrs: { title: _vm.$t("voiceToText") }
              }),
              _c("el-switch", {
                on: { change: _vm.changeSpeechConvertStatus },
                model: {
                  value: _vm.speechConvertStatus,
                  callback: function($$v) {
                    _vm.speechConvertStatus = $$v
                  },
                  expression: "speechConvertStatus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "secretChat" },
            [
              _c(
                "div",
                {
                  staticClass: "chatIcon",
                  attrs: { title: _vm.$t("encryptedMessaging") }
                },
                [_c("span", { staticClass: "iconfont_Me icon-a-zu10327" })]
              ),
              _c("el-switch", {
                on: { change: _vm.changeSecretChatStatus },
                model: {
                  value: _vm.secretChatStatus,
                  callback: function($$v) {
                    _vm.secretChatStatus = $$v
                  },
                  expression: "secretChatStatus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "operationItem",
              attrs: { title: _vm.$t("createGroup") }
            },
            [
              _c("div", {
                staticClass: "itemIcon",
                on: { click: _vm.createGroup }
              })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }