<template>
  <el-container :class="cls">
    <div class="imelink-groupmember__button" @click="changeShrink">
      <i :class="`el-icon-arrow-${shrink ? 'left' : 'right'}`" />
    </div>
    <el-header
      class="imelink-header"
      height="auto"
      :style="{
        opacity: shrink ? '0' : '1',
        marginLeft: shrink ? '-100px' : '0',
      }"
      >&nbsp;
    </el-header>
    <div
      class="imelink-groupmember__view"
      :style="{
        opacity: shrink ? '0' : '1',
      }"
    >
      <!-- 这里需要判断是否为管理员，如果为管理员，则无操作权限，因为不能对管理员操作 -->
      <div class="operationPopover" v-if="managerBlock">
        <el-popover
          placement="bottom-end"
          :visible-arrow="false"
          trigger="hover"
          popper-class="imOperationPopover"
          :popper-options="{
            boundariesElement: 'imelink-groupmember__view',
            gpuAcceleration: true,
          }"
        >
          <div class="moreOperation" slot="reference">
            <el-image
              style="width: 100%; height: 100%"
              :src="require('../../../assets/images/im/moreOperation.png')"
              :fit="'cover'"
            ></el-image>
          </div>
          <div class="operationPopoverBox">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('block')"
              placement="top-start"
            >
              <div class="blacklistIcon">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('../../../assets/images/im/blackListIcon.png')"
                  :fit="'cover'"
                ></el-image>
              </div>
            </el-tooltip>
            <el-switch
              v-model="isInBlackList"
              @change="changeIsInBlackList"
            ></el-switch>
          </div>
          <!-- IM中投诉，暂时未实现此功能 -->
          <!-- <div class="complaint">
            <span class="iconfont_Me icon-a-zu5510" @click="complaint"></span>
          </div> -->
        </el-popover>
      </div>
      <!-- 语音转换开关 -->
      <div class="voiceToText">
        <div class="voiceIcon" :title="$t('voiceToText')"></div>
        <el-switch
          v-model="speechConvertStatus"
          @change="changeSpeechConvertStatus"
        ></el-switch>
      </div>
      <!-- 密聊开关 -->
      <div class="secretChat">
        <div class="chatIcon" :title="$t('encryptedMessaging')">
          <span class="iconfont_Me icon-a-zu10327"></span>
        </div>
        <el-switch
          v-model="secretChatStatus"
          @change="changeSecretChatStatus"
        ></el-switch>
      </div>
      <div class="operationItem" :title="$t('createGroup')">
        <div class="itemIcon" @click="createGroup"></div>
      </div>
    </div>
  </el-container>
</template>
<script>
import * as RESTApi from "@/api/rest";
import { checkIfCanChat } from "@/api/im";
export default {
  inject: ["control"],
  data() {
    return {
      shrink: false,
      // 是否设置了语音转文字
      // speechConvertStatus: this.$store.state.imStore.otherPartyInfo.speechConvertStatus === 1 ? true : false,
      // 是否将此人拉入了黑名单
      isInBlackList: false,
    };
  },
  computed: {
    cls() {
      return [
        "operationInterface",
        { operationInterface__shrink: this.shrink },
      ];
    },
    managerBlock() {
      return !!(
        this.$store.state.imStore.otherPartyInfo.managerRole &&
        this.$store.getters.userInfo.id !==
          this.$store.state.imStore.otherPartyInfo.id &&
        this.$store.state.imStore.otherPartyInfo.managerRole != "1"
      );
    },
    speechConvertStatus: {
      get() {
        return this.$store.state.imStore.otherPartyInfo.speechConvertStatus ===
          1
          ? true
          : false;
      },
      set() {},
    },
    secretChatStatus: {
      get() {
        return this.$store.state.imStore.otherPartyInfo.encryptStatus === 1
          ? true
          : false;
      },
      set() {},
    },
  },
  watch: {
    // 监听聊天对象的变化，如果对象是单人，就开始获取他是否在黑名单
    "$store.state.imStore.otherPartyInfo.id": {
      handler(val, old) {
        if (
          val &&
          val !== old &&
          this.$store.state.imStore.otherPartyInfo.isUser
        ) {
          this.getBlacklistStatus();
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听是否需要刷新黑名单状态
    "$store.state.imStore.needRefreshBlackListStatus": {
      async handler(val, old) {
        if (val && val !== old) {
          await this.getBlacklistStatus();
          this.$store.commit("setNeedRefreshBlackListStatus", false);
        }
      },
      deep: true,
    },
  },
  methods: {
    // 改变此人在黑名单中的状态
    async changeIsInBlackList() {
      let result = new Object();
      // 如果设置为true，则将用户拉入黑名单
      if (this.isInBlackList) {
        let params = {
          blacklistProviderId: this.$store.state.imStore.otherPartyInfo.id,
        };
        result = await RESTApi.joinBlacklist(params);
      } else {
        result = await RESTApi.removeBlacklist(
          this.$store.state.imStore.otherPartyInfo.id
        );
      }
      if (result.code !== 200) {
        this.$message({
          type: "error",
          message: result.message,
        });
        this.isInBlackList = !this.isInBlackList;
      } else {
        // 通知状态管理器，黑名单需要改变了
        this.$store.commit("setBlackListChangeStatus", true);
      }
    },
    // 判断此人是否在你的黑名单中
    async getBlacklistStatus() {
      let result = await RESTApi.getBlacklistStatus([
        this.$store.state.imStore.otherPartyInfo.id,
      ]);
      if (result.code == 200 && result.data.data.length !== 0) {
        this.isInBlackList = result.data.data[0].positiveStatus;
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 切换语言转文字状态
    async changeSpeechConvertStatus() {
      let params = {
        targetType: this.$store.state.imStore.otherPartyInfo.isGroup
          ? "groupChat"
          : "chat",
        targetId: this.$store.state.imStore.otherPartyInfo.id,
        speechConvertStatus:
          this.$store.state.imStore.otherPartyInfo.speechConvertStatus === 1
            ? 0
            : 1,
        characterConvertStatus: this.$store.state.imStore.otherPartyInfo
          .characterConvertStatus,
      };
      let result = await RESTApi.settingChatState(params);
      if (result.code !== 200) {
        this.$message({
          type: "error",
          message: result.message,
        });
      } else {
        // 更新一下状态管理器的值,为避免直接改动污染数据，所以先深拷贝在存入管理器
        let otherPartyInfo = _.cloneDeep(
          this.$store.state.imStore.otherPartyInfo
        );
        otherPartyInfo.speechConvertStatus =
          result.data.data.speechConvertStatus;
        this.$store.commit("setOtherPartyInfo", otherPartyInfo);
      }
    },
    // 切换密聊状态
    async changeSecretChatStatus() {
      const isVip = this.$store.getters.userInfo.vipStatus;
      if (!isVip) {
        this.$confirm(
          this.$t("becomeApaidMemberCanUseEncryptionMessage"),
          this.$t("tips"),
          {
            confirmButtonText: " ",
            type: "warning",
            customClass: "melinked-tips",
            showCancelButton: false,
            confirmButtonClass: "el-icon-right my-confirm-btn",
          }
        )
          .then(() => {
            this.$store.commit("setVipModeStatus", true);
          })
          .catch(() => {});
        return false;
      }

      await this.changeSecretChatStatusForGlobal();
    },
    // 关闭打开侧边栏
    changeShrink() {
      this.shrink = !this.shrink;
    },
    // 点击创建群
    async createGroup() {
      const { currentContact } = this.control;
      let result = await checkIfCanChat(currentContact.id);
      if (result.code == 200 && result.data.data) {
        this.control.openSelection("creategroup");
        const component = this.control.$refs.contactList[0];
        component.$nextTick(() => {
          component.changeSelected(true, currentContact);
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    complaint() {
      console.log("IM投诉个人");
      // this.$store.commit('setComplaintImDialogVisible',true);
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.operationInterface__shrink
  width: 0 !important;
.operationInterface
  width 180px;
  height 100%;
  background #F9F9F9;
  flex: none;
  transition: all 0.3s ease;
  position: relative;
  .imelink-groupmember__button
    text-align: center;
    width: 18px;
    height: 100px;
    line-height: 100px;
    background: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    border-radius: 50px 0 0 50px;
    border-left: 1px solid #ddd;
    position-center-y();
    top: 40%;
    left: -18px;
    cursor: pointer;
    display: block;
    opacity: 0.5;
    &:hover
      background: #f1f1f1;
      opacity: 1;
  .imelink-header
    transition: all ease 0.5s;
    background #f9f9f9;
    border-bottom 1px solid #EEEEEE;
    border-radius 0px 5px 0px 0px;
  .imelink-groupmember__view
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-light();
    border-left: 1px solid #f1f1f1;
    padding 0 10px;
    transition: all ease 0.5s;
    .operationPopover
      width 100%;
      overflow hidden;
      display flex;
      align-items center;
      justify-content flex-end;
      margin 10px 0 15px 0;
      .imOperationPopover
        margin-top 6px !important;
      .moreOperation
        width 25px;
        height 25px;
    .searchHistory
      width: 100%;
      height: 40px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      cursor pointer;
      .title
        color: #000000;
        font-size: 14px;
      .iconStyle
        font-size: 18px;
        color: #DFDFDF;
    .voiceToText
      width 100%;
      padding: 10px 0;
      display flex;
      flex-flow row;
      align-items center;
      justify-content space-between;
      .voiceIcon
        width 30px;
        height 30px;
        background url("../../../assets/images/4.3.0/voiceToText.png") no-repeat;
        background-size 100% 100%;
    .secretChat
      width 100%;
      display flex;
      padding: 10px 0;
      flex-flow row;
      align-items center;
      justify-content space-between;
      .chatIcon
        width 30px;
        height 30px;
        background: #e5e5e5;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size:20px;
    .operationItem
      width 100%;
      display flex;
      flex-flow row;
      padding 10px 0;
      align-items center;
      justify-content space-between;
      .itemIcon
        width 30px;
        height 30px;
        cursor: pointer;
        background url("../../../assets/images/4.3.0/createGroup.png") no-repeat;
        background-size 100% 100%;
</style>
<style lang="stylus">
.imOperationPopover[x-placement^="bottom"]
  margin-top 6px !important;
  width 118px !important;
  // height 43px !important;
  border-radius 4px !important;
  padding 8px 12px !important;
  box-sizing border-box;
  .operationPopoverBox
    width 100%;
    height 100%;
    display flex;
    align-items center;
    justify-content space-between;
    .blacklistIcon
      width 26px;
      height 26px;
  .complaint{
    margin-top: 6px;
    cursor pointer;

    span{
      font-size: 27px;
    }
  }
</style>
